import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InfoContext from '../context/InfoContext'
import Home from './Home'
import AddGeneral from './AddGeneral'
import AddMedia from './AddMedia'
import AddFood from './AddFood'
import AddShopping from './AddShopping'
import Message from './Message'


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ScrollableTabsButtonAuto() {
    const {
      uid,
    } = useContext(InfoContext);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Home" {...a11yProps(0)} />
          <Tab label="General" {...a11yProps(1)} />
          <Tab label="Media" {...a11yProps(2)} />
          <Tab label="Food" {...a11yProps(3)} />
          <Tab label="Shopping" {...a11yProps(4)} />
          {/* <Tab label="Shopping" {...a11yProps(3)} /> */}
 
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Home />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {uid === '' ? <Message title="PLEASE LOGIN" />:<AddGeneral topic="General Link"/> }       
      </TabPanel>
      <TabPanel value={value} index={2}>
        {uid === '' ? <Message title="PLEASE LOGIN" />:<AddMedia topic="Media Link" /> }             
      </TabPanel>
      <TabPanel value={value} index={3}>
        {uid === '' ? <Message title="PLEASE LOGIN" />:<AddFood topic="Food Link" /> }  
        
      </TabPanel>      
      <TabPanel value={value} index={4}>
        {uid === '' ? <Message title="PLEASE LOGIN" />:<AddShopping topic="Shopping Link" />}  
       
      </TabPanel>

      
    </div>
  );
}
