import React ,{useContext} from 'react';
import InfoContext from '../context/InfoContext'
import {Jumbotron, Container} from 'react-bootstrap'


const Home = (props)=>{
  const {welcomeMessage, userName, uid} = useContext(InfoContext)
  return (
    <div>
      <Jumbotron fluid>
        <Container>
          {uid === '' ? <h1>Welcome to FavLinks</h1> : <h1>{welcomeMessage} {userName}</h1>}
          
          
          <p>            
            This App will help you put all your Favorite Links together
          </p>
          {uid === '' ?<div>
                         <li>Please Sign In (with google account)</li>
                         <li>Start Adding Links</li>
                         </div> : <p>Use Navbar tap to help you put your links in catagories</p>}
          
          
        </Container>
      </Jumbotron>
    </div>
  )
}
export default Home