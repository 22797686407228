import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {Col} from 'react-bootstrap'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoContext from '../context/InfoContext'
import db from '../firebase'
const useStyles = makeStyles(theme => ({
  root: {
     padding: theme.spacing(3, 2),
  },
}));

export default function LinkItem(props) {
  const classes = useStyles();
  const {uid, generalLinks, setGeneralLinks, mediaLinks, setMediaLinks, foodLinks, setFoodLinks, shoppingLinks, setShoppingLinks} = useContext(InfoContext)

  function deleteHandler(){
    // delete from db
    db.collection(`user/${uid}/${props.topic}/`).doc(props.id).delete().then(function() {
    }).catch(function(error) {
        console.error("Error removing document: ", error);
    });

    let temArray = generalLinks
    temArray = temArray.filter(function(item) { 
        return item.id !== props.id
    })
    setGeneralLinks(temArray)

    let temArray1 = mediaLinks
    temArray1 = temArray1.filter(function(item) { 
        return item.id !== props.id
    })
    setMediaLinks(temArray1)

    let temArray2 = foodLinks
    temArray2 = temArray2.filter(function(item) { 
        return item.id !== props.id
    })
    setFoodLinks(temArray2)

    let temArray3 = shoppingLinks
    temArray3 = temArray3.filter(function(item) { 
        return item.id !== props.id
    })
    setShoppingLinks(temArray3)
    
  }
  return (
    <div>
        <Col style={{paddingTop:'0.7em'}}>
            <Paper className={classes.root}>
                <a href={props.url} target='_blank' rel="noopener noreferrer">
                    <Typography variant="h5" component="h3">
                    {props.title}
                    </Typography>
                </a>
                <Typography component="p">
                {props.desc}
                </Typography>

                <Button onClick={deleteHandler} variant="contained" color="secondary" className={classes.button}>
                    Delete
                <DeleteIcon className={classes.rightIcon} />
                </Button>

            </Paper>
      </Col>
    </div>
  );
}