

import React from 'react'; 
import {Jumbotron} from 'react-bootstrap'

const Message = (props)=> {

    return(
            <Jumbotron>
                <h1>{props.title}</h1>
                <p>
                    {props.message}
                </p>

            </Jumbotron>
    )
}

export default Message

