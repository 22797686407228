import React,{useState, useContext, useEffect } from 'react';
import InforContext from '../context/InfoContext'
import {Button, Modal} from 'react-bootstrap'
import db from '../firebase'
import LinkItem from './LinkItem'
import {Row} from 'react-bootstrap'
import Message from './Message'

function AddVideo(props) {
    const [show, setShow] = useState(false);
    const {uid, mediaLinks, setMediaLinks} = useContext(InforContext)
    const [topic, setTopic] = useState(props.topic)
    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')
    const [url, setURL] = useState('')
    const [data, setData] = useState([])

    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    function SaveToDB(e){
      if(title === '' || url === ''){
        alert('please provide at least title and url')
      }
      else{
        setTopic(props.topic)
        setData([])
        db.collection(`user/${uid}/${topic}`).add({
          title: title,
          desc: desc,
          url: url
        })
        .then(function(docRef) {
            console.log("Document written with ID: ", docRef.id);
            let dataArray = [];
            dataArray = mediaLinks;
            const data = {
              id : docRef.id,
              title : title,
              desc : desc,
              url : url
            }
            dataArray.push(data)
            setMediaLinks(dataArray)
            setData(dataArray)
        })
        .catch(function(error) {
            console.error("Error adding document: ", error);
        });

        setShow(false);
        e.preventDefault()
        setTitle('')
        setDesc('')
        setURL('')
    }
  }
    useEffect(()=>{
      console.log('data first entry')
      setData(mediaLinks)
    },[mediaLinks])
    const displayEducationData = data.map((item)=><LinkItem key={item.id} topic={props.topic} id={item.id} title={item.title} desc={item.desc} url={item.url}/>)
    return (
      <>
      <div style={{paddingBottom:'1em'}}>
        <Button variant="primary" onClick={handleShow}>
          Add {props.topic} 
        </Button>
        </div>

        <Row>
          {data.length === 0 ? <Message title="You are currently have no links"/> : displayEducationData }
        </Row>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{props.topic}</Modal.Title>
          </Modal.Header>
                <input type='text' placeholder='Title' onChange={(e) => setTitle(e.target.value)}/>
                <input type='text' placeholder='URL' onChange={(e)=>setURL(e.target.value)}/>
                <textarea placeholder='Describtion' onChange={(e) => setDesc(e.target.value)}/>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button  variant="primary" onClick={SaveToDB}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  export default AddVideo
  
