import React from 'react';
import {Navbar} from 'react-bootstrap'

const Footer = (props)=>{

    return(
        <Navbar fixed='bottom' bg="light">
        <Navbar.Brand>
          <img
            src="/chicken_devil.PNG"
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="my logo"
          />
          
        </Navbar.Brand>
        <span className="text-muted">© 2019 FavLinks. All rights reserved.</span>
      </Navbar>
    )
}
export default Footer