import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import db, {firebase, GoogleProvider} from '../firebase'
import {Image} from 'react-bootstrap'
import InfoContext from '../context/InfoContext'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  const [uid,setUid] = useState('');
  const [userName,setUserName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userImage, setUserImage] = useState('')
  const [welcomeMessage, setWelcomeMessage] = useState('')
  const [displaySignIn, setDisplaySignIn] = useState('block')
  const [displaySignOut, setDisplaySignOut] = useState('none')
  //links part
  const [generalLinks, setGeneralLinks] = useState([])
  const [mediaLinks, setMediaLinks] = useState([])
  const [foodLinks, setFoodLinks] = useState([])
  const [shoppingLinks, setShoppingLinks] = useState([])

  function SignInWithGoogle(){
    // console.log('i am signing')
    firebase.auth().signInWithPopup(GoogleProvider).then(function(result) {
    // This gives you a Google Access Token. You can use it to access the Google API.
    // var token = result.credential.accessToken;
    // console.log(`this is my token ${token}`);
    // The signed-in user info.
    var user = result.user;
    // console.log(`user uid: ${user.uid}`)
    // console.log(`user email: ${user.displayName}`)
    // console.log(`user photoURL: ${user.photoURL}`)
    setUid(user.uid)
    setUserEmail(user.email)
    setUserName(user.displayName)
    setWelcomeMessage('Hello')
    setDisplaySignIn('none')
    setDisplaySignOut('block')
    setUserImage(user.photoURL)
    // alert('you are currently logged in')
    // ...
  }).catch(function(error) {
    alert(error)
  });
}
  function SignOut(){
    firebase.auth().signOut().then(function() {
      // Sign-out successful.
      setUid('')
      setUserEmail('')
      setUserName('')
      setWelcomeMessage('')
      setDisplaySignIn('block')
      setDisplaySignOut('none')
      setUserImage('')

    }).catch(function(error) {
      alert('there is something wrong')
      // An error happened.
    });
  }

useEffect(() => {

  // get stuff from database first time
}, [])

useEffect(()=>{
  const generalArr = []
  const mediaArr = []
  const foodArray =[]
  const shoppingArr = []
  if(uid){
    db.collection(`user/${uid}/General Link/`).get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
          let data = {
            id : doc.id,
            title : doc.data().title,
            desc : doc.data().desc,
            url : doc.data().url
          }
          generalArr.push(data)
      });
  });
  setGeneralLinks(generalArr)
    db.collection(`user/${uid}/Media Link/`).get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
          let data = {
            id : doc.id,
            title : doc.data().title,
            desc : doc.data().desc,
            url : doc.data().url
          }
          mediaArr.push(data)
      });
  });
  setMediaLinks(mediaArr)

    db.collection(`user/${uid}/Food Link/`).get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
          let data = {
            id : doc.id,
            title : doc.data().title,
            desc : doc.data().desc,
            url : doc.data().url
          }
          foodArray.push(data)
      });
  });
  setFoodLinks(foodArray)

    db.collection(`user/${uid}/Shopping Link/`).get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
          let data = {
            id : doc.id,
            title : doc.data().title,
            desc : doc.data().desc,
            url : doc.data().url
          }
          shoppingArr.push(data)
      });
  });
  setShoppingLinks(shoppingArr)
  }
}, [uid])




  return (
    <div className={classes.root}>
     <InfoContext.Provider 
      value={{
      userEmail, setUserEmail,
      userName, setUserName,
      uid, setUid,
      welcomeMessage,setWelcomeMessage,
      userImage,setUserImage, 
      displaySignOut,setDisplaySignOut,
      generalLinks, setGeneralLinks,
      mediaLinks, setMediaLinks,
      foodLinks, setFoodLinks,
      shoppingLinks, setShoppingLinks
      }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            FavLinks
          </Typography>
          <Image roundedCircle height='30em' src={userImage} />
          <Button onClick={SignInWithGoogle} style={{display:(displaySignIn)}} color="inherit">Login with Google</Button>
          <Button onClick={SignOut} style={{display:(displaySignOut)}} color="inherit">LogOut</Button>
        </Toolbar>
      </AppBar>
      <NavBar />
      <Footer />
      </InfoContext.Provider>
    </div>
  );
}
